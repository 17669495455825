import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, ref, onMounted, computed, getCurrentInstance, watchEffect, watch } from "vue";
import { DownCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { genFileId } from "element-plus";
import SvgIcon from "../components/SvgIcon.vue";
import util from "@/util/util";
import ExDialog from "../components/ExDialog.vue";
import { useRouter, useRoute } from "vue-router";
import permission from "@/util/permission";

import { ElNotification, ElMessageBox, ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
import 'element-plus/es/components/message-box/style/css';
import 'element-plus/es/components/message/style/css';
export default defineComponent({
  name: "StoreManagement",
  components: {
    SvgIcon,
    ExDialog,
    DownCircleOutlined
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const activityId = router.currentRoute.value.params.activityId;
    const storeTypeId = router.currentRoute.value.params.storeTypeId;
    var storeCurrentRow = null;
    const storeLoading = ref(false);
    const storeForm = ref(null);
    const storeTypeNum = ref(null);
    const activityIdNum = ref(null);
    const storeData = reactive({
      importMessageVisible: false,
      importMessages: [],
      data: [],
      paginationData: {
        pageSizes: [25, 50, 100],
        currentPage: 1,
        totalSize: 0,
        pageSize: 25
      },
      storePageRO: {
        searchKeyword: null,
        storeTypeIds: [],
        // storeTypeId: null,
        activityIds: [],
        city: null,
        checkAll: false,
        activityCheckAll: false,
        indeterminate: false,
        activityIndeterminate: false,
        provinceCity: null
      },
      storeTypeData: [],
      storeTypeProps: {
        label: "storeTypeNameZh",
        value: "storeTypeId"
      },
      storeStatusData: [],
      storeStatusColorData: [
      // "#f39c13", // Orange
      "#5F6DD0",
      // "#002266", // Dark Blue
      "#DFB229",
      // "#00a65a", // Green
      "#5AB46B",
      // "#dd4b39", // Red
      "#B45A5A", "#660066",
      // Purple
      "#117744",
      // Teal
      "#880011",
      // Maroon
      "#556600",
      // Olive
      "#660000",
      // Brown
      "#336699" // Navy
      ],

      storeStatusProps: {
        label: "storeStatusNameZh",
        value: "storeStatusId"
      },
      storeFormData: {
        storeName: null,
        storeCode: null,
        storeAddress: null,
        storeTypeId: null,
        city: null,
        contactName: null,
        contactMobile: null,
        contactEmail: null,
        storeStatusId: null
      },
      provinceDictData: [],
      storeFormRules: {
        storeName: [{
          required: true,
          message: "店铺名称必须填写",
          trigger: "blur"
        }],
        storeCode: [{
          required: true,
          message: "店铺编码必须填写",
          trigger: "blur"
        }],
        storeAddress: [{
          required: true,
          message: "店铺地址必须填写",
          trigger: "blur"
        }],
        storeTypeId: [{
          required: true,
          message: "店铺类型必须填写",
          trigger: "blur"
        }],
        city: [{
          required: true,
          message: "城市必须填写",
          trigger: "blur"
        }],
        contactName: [{
          required: true,
          message: "联系人必须填写",
          trigger: "blur"
        }],
        contactMobile: [{
          required: true,
          message: "联系方式必须填写",
          trigger: "blur"
        }],
        contactEmail: [{
          required: true,
          message: "邮箱必须填写",
          trigger: "blur"
        }],
        storeStatusId: [{
          required: true,
          message: "店铺状态必须填写",
          trigger: "blur"
        }]
      },
      storeUpdateVisible: false,
      selectedStoreTypes: [],
      selectedActivityIds: []
    });
    const activityData = reactive([]);
    const getStoreType = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getStoreType(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const selectAllActivity = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequest.selectAllActivity(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const getStoreStatus = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getStoreStatus(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const getStoreByPageRequest = async (index, pageSize, storePageRO) => {
      storeLoading.value = true;
      //传空数组后台报错，在这里判断下改成null
      let copyData = JSON.parse(JSON.stringify(storePageRO));
      if (copyData.storeTypeIds.length === 0) {
        copyData.storeTypeIds = null;
      }
      let storePageROPageInfo = {
        pageSize: pageSize,
        pageIndex: index,
        data: copyData
      };
      proxy.$api.storeRequest.getStoreByPage(storePageROPageInfo).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202100") {
            storeData.data.length = 0;
            let storeList = res.result.data.pageList;
            if (Array.isArray(storeList)) {
              for (let store of storeList) {
                if (store.activityName) {
                  store.activityNames = store.activityName.split(",");
                }
                storeData.data.push(store);
              }
            }
            const $table = storeTable.value;
            $table.reloadData(storeData.data);
            storeData.paginationData.totalSize = res.result.data.totalCount;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
        storeLoading.value = false;
      }).catch(error => {
        if (error.code != "TOKEN_MISSING") {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        storeLoading.value = false;
      });
    };
    const getProvinceDict = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getProvinceDict(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    watch(() => storeData.storePageRO.storeTypeIds, newVal => {
      storeData.selectedStoreTypes.length = 0;
      newVal.forEach(id => {
        const storeType = storeData.storeTypeData.find(item => item.storeTypeId === id);
        if (storeType) {
          storeData.selectedStoreTypes.push({
            value: storeType.storeTypeId,
            label: storeType.storeTypeNameZh
          });
        }
      });
    });
    watch(() => storeData.selectedStoreTypes, newVal => {
      if (newVal.length === 0) {
        onClear();
      }
    });
    const onClear = () => {
      storeData.storePageRO.storeTypeIds.length = 0;
      searchStoreClick();
    };
    watch(() => storeData.storePageRO.activityIds, newVal => {
      storeData.selectedActivityIds.length = 0;
      newVal.forEach(id => {
        const activity = activityData.find(item => item.activityId === id);
        if (activity) {
          storeData.selectedActivityIds.push({
            value: activity.activityId,
            label: activity.activityName
          });
        }
      });
    });
    watch(() => storeData.selectedActivityIds, newVal => {
      if (newVal.length === 0) {
        onActivityClear();
      }
    });
    const onActivityClear = () => {
      storeData.storePageRO.activityIds.length = 0;
      searchStoreClick();
    };
    const rowClassName = row => {
      if (row.row.storeStatusId === 1) {
        return "borderLeftBlue";
      } else if (row.row.storeStatusId === 2) {
        return "borderLeftYellow";
      } else if (row.row.storeStatusId === 3) {
        return "borderLeftGreen";
      } else if (row.row.storeStatusId === 4) {
        return "borderLeftRed";
      }
    };
    onMounted(async () => {
      let storeTypeList = await getStoreType();
      if (storeTypeList == null) {
        return;
      }
      storeData.storeTypeData.push(...storeTypeList);
      var storeStatusList = await getStoreStatus();
      if (storeStatusList == null) {
        return;
      }
      storeData.storeStatusData.push(...storeStatusList);
      var provinceDictList = await getProvinceDict();
      if (provinceDictList == null) {
        return;
      } else {
        provinceDictList = provinceDictList.map(provinceItem => ({
          value: provinceItem.province,
          label: provinceItem.province,
          children: provinceItem.cities.map(cityItem => ({
            value: cityItem.city,
            label: cityItem.city
          }))
        }));
      }
      storeData.provinceDictData.push(...provinceDictList);
      var activityDataList = await selectAllActivity();
      if (activityDataList == null) {
        return;
      }
      activityData.push(...activityDataList);
      if (route.params.storeTypeId) {
        storeData.storePageRO.storeTypeIds = [];
        storeData.storePageRO.storeTypeIds.push(parseInt(route.params.storeTypeId));
      } else {
        storeData.storePageRO.storeTypeIds = [];
      }
      storeData.storePageRO.activityId = parseInt(route.params.activityId);
      if (!storeData.storePageRO.activityId) {
        storeData.storePageRO.activityId = null;
      }
      getStoreByPageRequest(1, storeData.paginationData.pageSize, storeData.storePageRO);
    });
    const paginationSizeChange = val => {
      getStoreByPageRequest(storeData.paginationData.currentPage, storeData.paginationData.pageSize, storeData.storePageRO);
    };
    const paginationCurrentChange = val => {
      getStoreByPageRequest(storeData.paginationData.currentPage, storeData.paginationData.pageSize, storeData.storePageRO);
    };
    const headerCellClickEvent = ({
      column,
      triggerResizable,
      triggerSort,
      triggerFilter
    }) => {
      const $table = storeTable.value;
      if ($table) {
        // 如果触发了列的其他功能按钮
        if (column.sortable && !(triggerResizable || triggerSort || triggerFilter)) {
          if (column.order === "desc") {
            $table.clearSort();
          } else if (column.order === "asc") {
            $table.sort(column.field, "desc");
          } else {
            $table.sort(column.field, "asc");
          }
        }
      }
    };
    const storeClickEvent = row => {
      router.push({
        path: "/store-location/" + row.storeCode
      });
    };
    const storeTable = ref();
    const uploadStoreLocation = ref(null);
    const uploadStoreLocationPopoverVisible = ref(false);
    const uploadStoreLocationData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: []
    });
    const openUploadStoreLocationPopover = () => {
      uploadStoreLocation.value.clearFiles();
      uploadStoreLocationPopoverVisible.value = true;
    };
    const getSignature = async directory => {
      return new Promise((resolve, reject) => {
        proxy.$api.authorizationRequest.getSignature(directory).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      });
    };
    const handleStoreLocationBeforeUpload = async file => {
      uploadStoreLocationData.signatureData = await getSignature("excel/temp");
      let filename = file.name;
      let fullName = uploadStoreLocationData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadStoreLocationData.signatureData.dir + fullName;
      uploadStoreLocationData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadStoreLocationData.signatureData.policy,
        OSSAccessKeyId: uploadStoreLocationData.signatureData.accessId,
        signature: uploadStoreLocationData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadStoreLocationData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    const handleStoreLocationExceed = files => {
      uploadStoreLocation.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      uploadStoreLocation.value.handleStart(file);
      uploadStoreLocation.value.submit();
    };
    const handleStoreLocationOnSuccess = async (response, file, files) => {
      if (file.status === "success") {
        let uploadedRO = {
          fileName: uploadStoreLocationData.data.name,
          uploadedName: uploadStoreLocationData.data.key
        };
        importStoreLocationRequest(uploadedRO);
      }
    };
    const importStoreLocationRequest = async uploadedRO => {
      proxy.$api.storeRequest.importStoreLocation(uploadedRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202103") {
            uploadStoreLocationPopoverVisible.value = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };

    /////

    const uploadStore = ref(null);
    const uploadStorePopoverVisible = ref(false);
    const uploadStoreData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: []
    });
    const openUploadStorePopover = () => {
      uploadStore.value.clearFiles();
      uploadStorePopoverVisible.value = true;
    };
    const handleStoreBeforeUpload = async file => {
      uploadStoreData.signatureData = await getSignature("excel/temp");
      let filename = file.name;
      let fullName = uploadStoreData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadStoreData.signatureData.dir + fullName;
      uploadStoreData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadStoreData.signatureData.policy,
        OSSAccessKeyId: uploadStoreData.signatureData.accessId,
        signature: uploadStoreData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadStoreData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    const handleStoreExceed = files => {
      uploadStore.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      uploadStore.value.handleStart(file);
      uploadStore.value.submit();
    };
    const handleStoreOnSuccess = async (response, file, files) => {
      if (file.status === "success") {
        let uploadedRO = {
          fileName: uploadStoreData.data.name,
          uploadedName: uploadStoreData.data.key
        };
        importStoreRequest(uploadedRO);
      }
    };
    const importStoreRequest = async uploadedRO => {
      proxy.$api.storeRequest.importStore(uploadedRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202111") {
            uploadStorePopoverVisible.value = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else if (res.result.dataCode === "202177") {
            uploadStorePopoverVisible.value = false;
            storeData.importMessageVisible = true;
            storeData.importMessages = res.result.data;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const searchStoreClick = async () => {
      console.log(123);
      getStoreByPageRequest(1, storeData.paginationData.pageSize, storeData.storePageRO);
    };
    const confirmSaveStore = () => {
      storeForm.value.validate().then(() => {
        updateStoreRequest(storeData.storeFormData);
      }).catch(fields => {
        console.error("Validation errors:", fields);
      });
    };
    const cancelSaveStore = () => {
      storeForm.value.resetFields();
      storeData.storeUpdateVisible = false;
    };
    const updateStoreRequest = async storeUpdateRO => {
      proxy.$api.storeRequest.updateStore(storeUpdateRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202114") {
            util.propertyClone(res.result.data, storeCurrentRow);
            storeData.storeUpdateVisible = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const storeUpdateClick = row => {
      util.propertyClone(row, storeData.storeFormData);
      storeData.storeUpdateVisible = true;
      storeCurrentRow = row;
    };
    const deleteStoreRequest = async storeDeleteRO => {
      proxy.$api.storeRequest.deleteStore(storeDeleteRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202112") {
            util.removeArrayById("storeId", storeData.data, storeCurrentRow.storeId);
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const storeDeleteClick = row => {
      ElMessageBox.confirm("是否要删除" + row.storeName, "询问", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        storeCurrentRow = row;
        var storeDeleteRO = {
          storeCode: row.storeCode
        };
        deleteStoreRequest(storeDeleteRO);
      }).catch(() => {
        ElMessage({
          type: "info",
          message: "取消删除"
        });
      });
    };
    const goLocation = row => {
      router.push({
        path: "/store-location/" + row.storeCode
      });
    };
    const getStoreStatusColor = storeStatusId => {
      var findIndex = storeData.storeStatusData.findIndex(element => element.storeStatusId == storeStatusId);
      if (findIndex != -1) {
        return storeData.storeStatusColorData[findIndex];
      }
      return "";
    };
    const storeExportClick = () => {
      getStoreExport(storeData.storePageRO);
    };
    const getStoreExport = storePageRO => {
      let copyData = JSON.parse(JSON.stringify(storePageRO));
      if (copyData.storeTypeIds.length === 0) {
        copyData.storeTypeIds = null;
      }
      proxy.$api.storeRequest.getStoreExport(copyData).then(res => {}).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };

    //
    const onCheckboxChange = (storeTypeId, e) => {
      const checked = e.target.checked;
      const selected = storeData.storePageRO.storeTypeIds.slice();
      if (checked) {
        selected.push(storeTypeId);
      } else {
        const index = selected.indexOf(storeTypeId);
        if (index > -1) {
          selected.splice(index, 1);
        }
      }
      storeData.storePageRO.storeTypeIds = selected;
      updateCheckAllStatus();
      searchStoreClick();
    };
    const onCheckAllChange = e => {
      const checked = e.target.checked;
      storeData.storePageRO.storeTypeIds = checked ? storeData.storeTypeData.map(item => item.storeTypeId) : [];
      updateCheckAllStatus();
      searchStoreClick();
    };
    const updateCheckAllStatus = () => {
      const totalOptions = storeData.storeTypeData.length;
      const selectedLength = storeData.storePageRO.storeTypeIds.length;
      storeData.storePageRO.checkAll = selectedLength === totalOptions;
      storeData.storePageRO.indeterminate = selectedLength > 0 && selectedLength < totalOptions;
    };
    const onActivityCheckboxChange = (activityId, e) => {
      const checked = e.target.checked;
      const selected = storeData.storePageRO.activityIds.slice();
      if (checked) {
        selected.push(activityId);
      } else {
        const index = selected.indexOf(activityId);
        if (index > -1) {
          selected.splice(index, 1);
        }
      }
      storeData.storePageRO.activityIds = selected;
      updateActivityCheckAllStatus();
      searchStoreClick();
    };
    const onActivityCheckAllChange = e => {
      const checked = e.target.checked;
      storeData.storePageRO.activityIds = checked ? activityData.map(item => item.activityId) : [];
      updateActivityCheckAllStatus();
      searchStoreClick();
    };
    const updateActivityCheckAllStatus = () => {
      debugger;
      const totalOptions = activityData.length;
      const selectedLength = storeData.storePageRO.activityIds.length;
      storeData.storePageRO.activityCheckAll = selectedLength === totalOptions;
      storeData.storePageRO.activityIndeterminate = selectedLength > 0 && selectedLength < totalOptions;
    };
    const cityChange = value => {
      if (storeData.storePageRO.provinceCity) {
        storeData.storePageRO.provinceCity = value[value.length - 1];
      }
      getStoreByPageRequest(1, storeData.paginationData.pageSize, storeData.storePageRO);
    };
    return {
      permission,
      storeLoading,
      storeForm,
      storeData,
      paginationSizeChange,
      paginationCurrentChange,
      headerCellClickEvent,
      storeTable,
      openUploadStoreLocationPopover,
      uploadStoreLocation,
      uploadStoreLocationPopoverVisible,
      uploadStoreLocationData,
      handleStoreLocationExceed,
      handleStoreLocationBeforeUpload,
      handleStoreLocationOnSuccess,
      openUploadStorePopover,
      uploadStore,
      uploadStorePopoverVisible,
      uploadStoreData,
      handleStoreExceed,
      handleStoreBeforeUpload,
      handleStoreOnSuccess,
      searchStoreClick,
      confirmSaveStore,
      cancelSaveStore,
      storeUpdateClick,
      storeDeleteClick,
      goLocation,
      storeTypeNum,
      activityIdNum,
      activityData,
      storeClickEvent,
      getStoreStatusColor,
      storeExportClick,
      onCheckboxChange,
      onCheckAllChange,
      rowClassName,
      cityChange,
      onActivityCheckAllChange,
      onActivityCheckboxChange
    };
  }
});